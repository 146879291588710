import { createApp } from 'vue'
import App from './App.vue'
import i18n from './i18n';

import './assets/main.scss'
import './assets/fonts.scss'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue-icons.css'
import bootstrap from 'bootstrap/dist/js/bootstrap.bundle.js'
import router from './router'


const app = createApp(App);
app.use(bootstrap);
app.use(router);
app.use(i18n);
app.mount('#app');
//createApp(App).use(bootstrap, router).mount('#app')
