<template>
  <div class="home-page">
    <div class="header">
      <div class="cover-img">
        <div class="img"></div>
        <div class="content">
          <Header></Header>
          <div class="wrapper">
            <div class="header">
              <div class="header__section pt-lg-5 pt-md-3 pt-sm-2 mt-lg-4">
                <div :class="{ 'show': showContent }" class="header__section-info">
                  <div class="title">{{ $t('header.aviation') }}<br> <span>Mulag</span></div>
                </div>
                <transition name="fade">
                  <div v-if="showImage" class="header__section-img"></div>
                </transition>
              </div>
            </div>
            <div class="description" ref="description">
              {{ $t('aviation.mulag.mulagDescription') }}
            </div>
          </div>
        </div>
      </div>

    </div>
    <div class="body-section">
      <div class="cover-img">
        <div class="img">
          <div class="img2"></div>
          <div class="img1"></div>
          <div class="img2"></div>
        </div>

        <div class="content">
          <div class="box box__left" :class="{ 'show': showLeft}">
            <div class="blue-container blue-container__left">
              <div class="container-content">
                <div class="container-content__title">
                  {{ $t('aviation.mulag.loadingUnloaging') }}
                </div>
                <div class="container-content__description container-content__description-left">
                  {{ $t('aviation.mulag.loadingUnloagingDescription') }}
                </div>
                <div class="container-content__pdfs">
                  <a href="https://www.mulag.de/en/ground-support-equipment/applications/loading-unloading/" class="btn">
                    {{ $t('viewDetails') }}
                  </a>
                </div>
              </div>
              <img class="box-img box-img--left" src="~@/assets/images/loading-unloading.png" alt="">
            </div>
          </div>

          <div class="box box__right" :class="{ 'show': showRight }">
            <div class="blue-container blue-container__right">
              <div class="container-content">
                <div class="container-content__title">
                  {{ $t('aviation.mulag.baggage') }}
                </div>
                <div class="container-content__description container-content__description-right">
                  {{ $t('aviation.mulag.baggageDescription') }}

                </div>
                <div class="container-content__pdfs container-content__pdfs-right">
                  <a href="https://www.mulag.de/en/ground-support-equipment/applications/baggage-handling/" class="btn">
                    {{ $t('viewDetails') }}
                  </a>
                </div>
              </div>
              <img class="box-img box-img--right" src="~@/assets/images/baggage.png" alt="">
            </div>
          </div>

          <div class="box box__left" :class="{ 'show': showLeft2 }">
            <div class="blue-container blue-container__left">
              <div class="container-content">
                <div class="container-content__title">
                  {{ $t('aviation.mulag.cargo') }}
                </div>
                <div class="container-content__description container-content__description-left">
                  {{ $t('aviation.mulag.cargoDescription') }}
                </div>
                <div class="container-content__pdfs">
                  <a href="https://www.mulag.de/en/ground-support-equipment/applications/cargo/" class="btn">
                    {{ $t('viewDetails') }}
                  </a>
                </div>
              </div>
              <img class="box-img box-img--left" src="~@/assets/images/cargo.png" alt="">
            </div>

          </div>

          <div class="box box__right" :class="{ 'show': showRight2 }">
            <div class="blue-container blue-container__right">
              <div class="container-content">
                <div class="container-content__title">
                  {{ $t('aviation.mulag.pushback') }}
                </div>
                <div class="container-content__description container-content__description-right">
                  {{ $t('aviation.mulag.pushbackDescription') }}
                </div>
                <div class="container-content__pdfs container-content__pdfs-right">
                  <a href="https://www.mulag.de/en/ground-support-equipment/applications/pushback/" class="btn">
                    {{ $t('viewDetails') }}
                  </a>
                </div>
              </div>
              <img class="box-img box-img--right" src="~@/assets/images/pushback.png" alt="">
            </div>
          </div>
          <div class="box box__left" :class="{ 'show': showLeft3}">
            <div class="blue-container blue-container__left">
              <div class="container-content">
                <div class="container-content__title">
                  {{ $t('aviation.mulag.military') }}
                </div>
                <div class="container-content__description container-content__description-left">
                  {{ $t('aviation.mulag.militaryDescription') }}
                </div>
                <div class="container-content__pdfs">
                  <a href="https://www.mulag.de/en/ground-support-equipment/applications/military-applications/"
                     class="btn">
                    {{ $t('viewDetails') }}
                  </a>
                </div>
              </div>
              <img class="box-img box-img--left" src="~@/assets/images/military-applications.png" alt="">
            </div>
          </div>
          <!--          <div class="marquee">
                      <div class="marquee-content">
                        <img v-for="logo in partners" :src="logo.src" :alt="logo.alt" :key="logo.id" class="logo">
                        <img v-for="logo in partners" :src="logo.src" :alt="logo.alt" :key="logo.id" class="logo">
                        <img v-for="logo in partners" :src="logo.src" :alt="logo.alt" :key="logo.id" class="logo">
                      </div>
                    </div>-->
        </div>
      </div>

    </div>
    <div class="footer">
      <div class="cover-img">
        <div class="img">
          <div class="img3"></div>
        </div>
        <div class="content">
          <FooterSection></FooterSection>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import FooterSection from "@/components/Footer.vue";
import Header from "@/components/Header.vue";

export default {
  name: 'AvioMulag',
  components: {
    FooterSection,
    Header
  },
  data() {
    return {
      showImage: false,
      showContent: false,
      showLeft: false,
      showRight: false,
      showLeft2: false,
      showRight2: false,
      showLeft3: false,
      showRight3: false,
      showLeft4: false,
      partners: [
        {id: 1, src: './partners/aksa.png', alt: 'Logo 1'},
        {id: 2, src: './partners/mulag-logo.png', alt: 'Logo 3'},
        {id: 3, src: './partners/Aviogei.png', alt: 'Logo 3'},
        {id: 4, src: './partners/prekings.png', alt: 'Logo 12'},
        {id: 5, src: './partners/cummins.png', alt: 'Logo 5'},
        {id: 6, src: './partners/bertoli.png', alt: 'Logo 14'},
        {id: 7, src: './partners/dinobus.svg', alt: 'Logo 3'},
        {id: 8, src: './partners/fg-wilson.png', alt: 'Logo 8'},
        {id: 9, src: './partners/green-power.png', alt: 'Logo 9'},
        {id: 10, src: './partners/kohler-sdmo.png', alt: 'Logo 10'},
        {id: 11, src: './partners/mtu.png', alt: 'Logo 11'},
        {id: 12, src: './partners/prekings.png', alt: 'Logo 12'},
        {id: 13, src: './partners/teksan.png', alt: 'Logo 13'},
        {id: 14, src: './partners/boschung-logo.png', alt: 'Logo 3'},
        {id: 15, src: './partners/visa.png', alt: 'Logo 14'},
        {id: 16, src: './partners/mulag-logo.png', alt: 'Logo 3'},
        {id: 17, src: './partners/bertoli.png', alt: 'Logo 14'},
        {id: 18, src: './partners/emsa.png', alt: 'Logo 7'},
        {id: 19, src: './partners/boschung-logo.png', alt: 'Logo 3'},
      ]
    };
  },
  mounted() {
    this.showImage = true;
    setTimeout(() => {
      this.$nextTick(() => {
        this.$refs.description.classList.add('fade-in');
      });
    }, 500);
    setTimeout(() => {
      this.showContent = true;
    }, 500);
    setTimeout(() => {
      this.showLeft = true;
    }, 1000);
    setTimeout(() => {
      this.showRight = true;
    }, 1600);
    setTimeout(() => {
      this.showLeft2 = true;
    }, 2500);
    setTimeout(() => {
      this.showRight2 = true;
    }, 2800);
    setTimeout(() => {
      this.showLeft3 = true;
    }, 3200);
    setTimeout(() => {
      this.showRight3 = true;
    }, 3300);
    setTimeout(() => {
      this.showLeft4 = true;
    }, 3400);

  },
  methods: {}

}
</script>

<style lang="scss" scoped>
@import "~@/assets/main.scss";

.home-page {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin: 0;
  padding: 0;
  overflow-y: auto;
}

.header {
  position: relative;
  overflow: hidden;

  .cover-img {
    .img {
      background-image: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0.85) 85%), url('@/assets/images/top-bg.png');
      background-repeat: no-repeat;
      background-size: cover;
      position: relative;
      width: 100%;
      height: 1000px;
      @include respond(phone) {
        height: 850px;
      }
      @include respond(from-phone-to-tab) {
        height: 850px;
      }
    }

    img {
      position: relative;
      top: 5px;
    }

    .content {
      position: absolute;
      top: 0;
      width: 100%;
    }
  }
}

.body-section {
  position: relative;
  overflow: hidden;

  .cover-img {
    .img {
      position: relative;
      // display: flex;
      //  flex-direction: column;
    }

    .img1 {
      background-image: linear-gradient(to top, rgb(255 255 255), #ffffffb0), linear-gradient(to bottom, rgb(255 255 255), #ffffff42), url('@/assets/images/bottom-bg.png');
      background-repeat: no-repeat;
      background-size: cover;
      width: 100%;
      height: 400px;
      @include respond(phone) {
        height: 430px;
      }
      @include respond(from-phone-to-tab) {
        height: 550px;
      }
    }

    .img2 {
      background-image: linear-gradient(to top, rgb(255 255 255), rgb(255 255 255 / 40%)), linear-gradient(to bottom, rgb(255 255 255 / 72%), rgba(255, 255, 255, 0.2588235294)), url('@/assets/images/interior-large-logistics-warehouse-ai-generative.png');
      background-repeat: no-repeat;
      background-size: cover;
      //  position: relative;
      width: 100%;
      height: 1050px;
      @include respond(phone) {
        height: 900px;
      }
      @include respond(lil-phone) {
        height: 1000px;
      }
    }

    .img3 {
      background-image: linear-gradient(to top, rgba(88, 135, 198, 0.74), #5887C6BD), linear-gradient(to bottom, rgb(88, 135, 198, 0.74), #5887C6BD), url('@/assets/images/bottom-bg.png');
      background-repeat: no-repeat;
      background-size: cover;
      // position: relative;
      width: 100%;
      height: 830px;
      @include respond(from-phone-to-tab) {
        height: 620px;
      }
    }

    img {
      position: relative;
      top: 5px;
    }

    .content {
      position: absolute;
      top: 0;
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 20px;

    /*  .box {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 70%;
        gap: 20px;
        background: linear-gradient(75deg, rgba(93, 132, 183, 0.85) 0%, rgba(36, 85, 151, 0.97) 100%);
        padding: 50px;
        @include respond(from-phone-to-tab) {
          width: 90%;
        }
        @include respond(phone) {
          padding: 20px;
          width: 90%;
        }

        &:hover {
          box-shadow: 0 5px 20px 5px rgb(9, 42, 75);
        }

        &-left {
          border-radius: 0 10px 10px 0;
          transform: translateX(-100%);
          opacity: 0;
          transition: transform 2s ease-out, opacity 3s ease-out;
          padding-left: 14%;
          text-align: left;
          @include respond(phone) {
            padding-left: 5%;
          }

          &.show {
            transform: translateX(0);
            opacity: 1;
          }
        }

        &-right {
          border-radius: 10px 0 0 10px;
          align-items: flex-end;
          transform: translateX(200%);
          opacity: 0;
          transition: transform 2s ease-out, opacity 3s ease-out;
          padding-right: 14%;
          text-align: right;
          @include respond(phone) {
            padding-right: 5%;
          }

          &.show {
            transform: translateX(45%);
            opacity: 1;

            @include respond(from-phone-to-tab) {
              transform: translateX(15%);
            }
            @include respond(phone) {
              transform: translateX(14%);
            }
          }
        }

        &-img {
          border-radius: 10px;
          width: 450px;
          height: 300px;
          object-fit: cover;
          position: absolute;
          top: 15%;
          transition: transform 1.2s ease-out, opacity 4s ease-out;
          @include respond(from-phone-to-tab) {
            display: none;
          }
          @include respond(phone) {
            display: none;
          }

          &-left {
            left: 70%;
          }

          &-right {
            right: 70%;
          }

          &.show {
            opacity: 1;
          }
        }

        &__title {
          font-size: 40px;
          font-family: "Montserrat";
          color: white;
          font-weight: bold;
          text-transform: uppercase;
          line-height: 0.938;
          max-width: 525px;
          @include respond(phone) {
            font-size: 25px;
          }
        }

        &__description {
          font-size: 22px;
          font-family: "Karla";
          text-align: justify;
          color: white;
          line-height: 1;
          padding: 10px 0;
          max-width: 500px;
          @include respond(phone) {
            font-size: 20px;
          }
          !*     @include respond(tab-land) {
                 max-width: 500px;
               }
               @media only screen and (min-width: 1201px) and (max-width: 1499px) {
                 max-width: 500px;
               }*!
        }

        &__pdfs {
          display: flex;
          gap: 20px;

          .btn {
            background-color: white;
            color: $primary-blue;
            padding: 10px 25px;
            text-transform: uppercase;
            text-decoration: none;
            font-weight: bold;
            transition: transform 0.7s ease-out, color 1s ease;

            &:hover {
              transform: scale(1.1);
              color: $light-blue;
              //  box-shadow: 0 5px 20px 5px rgb(9, 42, 75);
            }
          }
        }
      }*/
    }
  }

  .marquee {
    width: 100%;
    overflow: hidden;
    position: relative;
    margin-top: 50px;
    @include respond(phone) {
      margin-top: 0;
    }
  }

  .marquee-content {
    display: flex;
    width: calc(100% * 2); /* Double the container size for duplication */
    animation: marquee 20s linear infinite;
  }

  .logo {
    max-width: 150px;
    height: 125px; /* Set a fixed height */
    flex-shrink: 0;
    margin-right: 50px; /* Space between logos */
  }
}

.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .header {
    width: 70vw;
    @include respond(tab-land) {
      width: 95vw;
    }
    @media only screen and (min-width: 1201px) and (max-width: 1499px) {
      width: 95vw;
    }

    &__section {
      display: flex;
      width: 100%;
      justify-content: space-between;
      //   align-items: flex-start;
      align-items: center;
      margin-top: 40px;
      @include respond(phone) {
        flex-direction: column;
        margin-top: 20px;
      }

      &-info {
        display: flex;
        flex-direction: column;
        gap: 10px;
        max-width: 530px;
        width: 100%;
        text-align: left;

        transform: translateY(-100%);
        opacity: 0;
        transition: transform 1.2s ease-out, opacity 2s ease-out;
        @include respond(phone) {
          text-align: center;
        }

        &.show {
          transform: translateX(0);
          opacity: 1;
        }

        .title {
          font-size: 100px;
          font-family: "Montserrat";
          color: $primary-blue;
          font-weight: bold;
          text-transform: uppercase;
          line-height: 0.938;
          text-align: center;
          @include respond(phone) {
            font-size: 30px !important;
            width: 100%;
          }
          span {
            color: $light-blue;
          }
        }

      }

      .fade-enter-active, .fade-leave-active {
        transition: opacity 1s ease-in;
      }

      .fade-enter-from, .fade-leave-to {
        opacity: 0;
      }

      .fade-enter-to, .fade-leave-from {
        opacity: 1;
      }

      &-img {
        background-image: url('~@/assets/images/mulag-avio-oprema-naslovna.png');
        border-radius: 10px;
        width: 500px;
        height: 300px;
        background-size: cover;
        @include respond(phone) {
          display: block !important;
          width: 90vw;
          height: 200px;
          border-radius: 8px;
        }
      }
    }

    .title {
      font-size: 50px;
      font-family: "Montserrat";
      color: $primary-blue;
      font-weight: bold;
      text-transform: uppercase;
      line-height: 1.3;
      @include respond(from-phone-to-tab) {
        font-size: 40px;
      }
    }
  }

  .description {
    max-width: 70vw;
    text-align: center;
    color: $primary-blue;
    font-size: 22px;
    margin-top: 80px;
    font-family: "Karla";
    opacity: 0;
    transition: opacity 3s ease-out;
    @include respond(phone) {
      text-align: justify;
      margin-top: 40px;
      font-size: 20px;
      max-width: 95vw;
    }
    @include respond(lil-phone) {
      margin-top: 20px;
    }
  }

  .description.fade-in {
    opacity: 1;
  }
}

.footer {
  position: relative;
  overflow: hidden;

  .cover-img {
    .img {
      position: relative;
      // display: flex;
      //  flex-direction: column;
    }

    .img3 {
      background-image: linear-gradient(to top, rgba(88, 135, 198, 0.74), #5887C6BD), linear-gradient(to bottom, rgb(88, 135, 198, 0.74), #5887C6BD), url('@/assets/images/bottom-bg.png');
      background-repeat: no-repeat;
      background-size: cover;
      // position: relative;
      width: 100%;
      height: 950px;
      @include respond(from-phone-to-tab) {
        height: 620px;
      }
      @include respond(phone) {
        height: 650px;
      }
    }

    img {
      position: relative;
      top: 5px;
    }

    .content {
      position: absolute;
      top: 0;
      width: 100%;
    }
  }
}

</style>
