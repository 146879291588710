import {createRouter, createWebHistory} from 'vue-router'
import Home from '@/components/Home.vue'
import AboutUs from '@/components/pages/AboutUs.vue'
import ElektroAgregati from "@/components/pages/ElektroAgregati.vue";
//import ElektroMotori from "@/components/pages/BoschungSaobracaj.vue";
import AvioOprema from "@/components/pages/avio-oprema/AvioOprema.vue";
import UpsOvi from "@/components/pages/UpsOvi.vue";
import OsiLiftovi from "@/components/pages/OsiLiftovi.vue";
import BoschungSaobracaj from "@/components/pages/BoschungSaobracaj.vue";
import MulagSaobracaj from "@/components/pages/MulagSaobracaj.vue";
import AvioMulag from "@/components/pages/avio-oprema/AvioMulag.vue";
import AvioFox from "@/components/pages/avio-oprema/AvioFox.vue";
import AvioBertoli from "@/components/pages/avio-oprema/AvioBertoli.vue";
import Services from "@/components/pages/Services.vue";
import Railway from "@/components/pages/Railway.vue";
import AvioBoschung from "@/components/pages/avio-oprema/AvioBoschung.vue";

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/about-us',
        name: 'AboutUs',
        component: AboutUs
    },
    {
        path: '/services',
        name: 'ServicesPage',
        component: Services
    },
    {
        path: '/elektro-agregati',
        name: 'ElektroAgregati',
        component: ElektroAgregati
    },
    {
        path: '/saobracajnica-boschung',
        name: 'BoschungSaobracaj',
        component: BoschungSaobracaj
    },
    {
        path: '/saobracajnica-mulag',
        name: 'MulagSaobracaj',
        component: MulagSaobracaj
    },
    {
        path: '/avio-oprema-aviogei',
        component: AvioOprema,

    },
    {
        path: '/avio-oprema-boschung',
        component: AvioBoschung,

    },
    {
        path: '/avio-oprema-mulag',
        name: 'AvioMulag',
        component: AvioMulag
    },
    {
        path: '/avio-oprema-bliss-fox',
        name: 'AvioFox',
        component: AvioFox
    },
    {
        path: '/avio-oprema-bertoli',
        name: 'AvioBertoli',
        component: AvioBertoli
    },
    {
        path: '/ups-ovi',
        name: 'UpsOvi',
        component: UpsOvi
    },
    {
        path: '/liftovi-za-osi',
        name: 'OsiLiftovi',
        component: OsiLiftovi
    },
    {
        path: '/zeljeznica',
        name: 'RailwayPage',
        component: Railway
    }
]

const router = createRouter({
    history: createWebHistory(),
    routes,
/*    scrollBehavior(to, from, savedPosition) {
        if (to.hash) {
            console.log(to)
            return {
                el: to.hash,
                behavior: 'smooth',
            };
        } else if (savedPosition) {
            console.log('test2')
            return savedPosition;
        } else {
          //  console.log('test3')
        //    return { top: 0 };
        }
    },*/
    scrollBehavior(to) {
        if (to.hash) {
            return new Promise(resolve => {
                setTimeout(() => {
                    resolve({ el: to.hash });
                }, 1000);
            });
        } else {
            return {top: 0};
        }
    },
})
export default router
