<template>
  <div class="home-page">
    <div class="header">
      <div class="cover-img">
        <div class="img"></div>
        <div class="content">
          <Header></Header>
          <HeadingSection></HeadingSection>
        </div>
      </div>

    </div>
    <div class="body-section">
      <div class="cover-img">
        <div class="img">
          <div class="img1"></div>
          <div class="img2"></div>
          <div class="img3"></div>
        </div>

        <div class="content">
          <BodySection></BodySection>
        </div>
      </div>
    </div>
    <div class="footer">
      <div class="cover-img">
        <div class="img">
          <div class="img3"></div>
        </div>

        <div class="content">
          <FooterSection></FooterSection>
        </div>
      </div>
    </div>
  </div>

</template>

<script>

import HeadingSection from "@/components/HeadingSection.vue";
import BodySection from "@/components/BodySection.vue";
import FooterSection from "@/components/Footer.vue";
import Header from "@/components/Header.vue";

export default {
  name: 'HomePage',
  components: {
    FooterSection,
    HeadingSection,
    BodySection,
    Header
  },
  data() {
    return {
      images: [
        require('@/assets/images/top-bg.png'),
        require('@/assets/images/bottom-bg.png'),
        require('@/assets/images/interior-large-logistics-warehouse-ai-generative.png'),
        require('@/assets/images/bottom-bg.png'),
        require('@/assets/images/bottom-bg.png'),
      ],
    };
  }

}
</script>

<style lang="scss">
@import "~@/assets/main.scss";
.home-page {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin: 0;
  padding: 0;
  overflow-y: auto;
}

.background-image {
  flex: 1;
  background-size: cover;
  background-position: center;
  min-height: 20vh;
  position: relative;
}

.background-image .overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(173, 156, 156, 0.5);
}
.header {
  position: relative;
 // overflow: hidden;
  //overflow: visible;
  .cover-img {
   // overflow: visible;
    .img {
      background-image: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0.85) 85%), url('@/assets/images/top-bg.png');
      background-repeat: no-repeat;
      background-size: cover;
      position: relative;
      width: 100%;
      height: 820px;
      @include respond(from-phone-to-tab) {
        height: 720px;
      }
      @include respond(phone) {
        height: 650px;
      }
    }

    img {
      position: relative;
      top: 5px;
    }
    .content {
      position: absolute;
      top: 0;
      width: 100%;
    }
  }
}
.body-section {
  position: relative;
  overflow: hidden;
  @include respond(phone) {

  }
  .cover-img {
    .img {
      position: relative;
      // display: flex;
      //  flex-direction: column;
    }
    .img1 {
      background-image: linear-gradient(to top, rgb(255 255 255), #ffffffb0), linear-gradient(to bottom, rgb(255 255 255), #ffffff42), url('@/assets/images/bottom-bg.png');
      background-repeat: no-repeat;
      background-size: cover;
      width: 100%;
      height: 550px;
      @include respond(phone) {
        height: 800px;
      }
    }
    .img2 {
      background-image: linear-gradient(to top, rgb(255 255 255), rgb(255 255 255 / 40%)), linear-gradient(to bottom, rgb(255 255 255 / 72%), rgba(255, 255, 255, 0.2588235294)), url('@/assets/images/interior-large-logistics-warehouse-ai-generative.png');
      background-repeat: no-repeat;
      background-size: cover;
      //  position: relative;
      width: 100%;
      height: 1050px;
      @include respond(phone) {
        height: 1100px;
      }
/*      @include respond(tab-land) {
        height: 1200px;
      }
      @media only screen and (min-width: 1201px) and (max-width: 1499px) {
        height: 1200px;
      }*/
    }
    .img3 {
      background-image: linear-gradient(to top, rgba(88, 135, 198, 0.74), #5887C6BD), linear-gradient(to bottom, rgb(88, 135, 198, 0.74), #5887C6BD), url('@/assets/images/bottom-bg.png');
      background-repeat: no-repeat;
      background-size: cover;
      // position: relative;
      width: 100%;
      height: 830px;
      @include respond(from-phone-to-tab) {
        height: 650px;
      }
      @include respond(phone) {
        height: 500px;
      }
    }

    img {
      position: relative;
      top: 5px;
    }
    .content {
      position: absolute;
      top: 0;
      width: 100%;
    }
  }
}
.footer {
  position: relative;

  overflow: hidden;
  .cover-img {
    .img {
      position: relative;
      // display: flex;
      //  flex-direction: column;
    }
    .img3 {
      background-image: linear-gradient(to top, rgba(88, 135, 198, 0.74), #5887C6BD), linear-gradient(to bottom, rgb(88, 135, 198, 0.74), #5887C6BD), url('@/assets/images/bottom-bg.png');
      background-repeat: no-repeat;
      background-size: cover;
      // position: relative;
      width: 100%;
      height: 950px;
      @include respond(from-phone-to-tab) {
        height: 620px;
      }
      @include respond(phone) {
        height: 650px;
      }
    }

    img {
      position: relative;
      top: 5px;
    }
    .content {
      position: absolute;
      top: 0;
      width: 100%;

    }
  }
}
@media (max-width: 768px) {
  .background-image {
    min-height: 40vh;
  }
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  // margin-top: 60px;
}
</style>
