<template>
  <div id="app">
    <router-view></router-view>
  </div>

<!--  <HomePage></HomePage>-->
</template>
<script>
//import HomePage from "@/components/Home.vue";

export default {
  name: 'App',
  components: {
  //  HomePage
  },
  data() {
    return {
      images: [
        require('@/assets/images/top-bg.png'),
        require('@/assets/images/bottom-bg.png'),
        require('@/assets/images/interior-large-logistics-warehouse-ai-generative.png'),
        require('@/assets/images/bottom-bg.png'),
        require('@/assets/images/bottom-bg.png'),
      ],
    };
  }

}
</script>

<style lang="scss">
@import "assets/main.scss";
.home-page {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin: 0;
  padding: 0;
  overflow-y: auto;
}

.background-image {
  flex: 1;
  background-size: cover;
  background-position: center;
  min-height: 20vh;
  position: relative;
}

.background-image .overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(173, 156, 156, 0.5);
}
.header {
  position: relative;
  overflow: hidden;
  .cover-img {
    .img {
      background-image: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0.85) 85%), url('@/assets/images/top-bg.png');
      background-repeat: no-repeat;
      background-size: cover;
      position: relative;
      width: 100%;
      height: 750px;
    }

    img {
      position: relative;
      top: 5px;
    }
    .content {
 /*     display: flex;
      flex-direction: column;
      align-items: center;*/

      position: absolute;
      top: 0;
    //  left: 0;
    //  max-width: 80vw;
      width: 100%;
     // transform: translate(-50%, -50%);
      &-title {
        color: #fff;
        text-align: center;
        font-size: 32px;
        font-weight: 700;
        line-height: 39px;
      }
      &-button {
        margin-top: 45px;
        button {
          min-width: 219px;
          font-weight: 700;
          font-size: 18px;
          line-height: 24px;
          text-align: center;
          border-radius: 30px;
          cursor: pointer;
          padding: 20px;
          color: #ffffff;
          border: none;
        }
      }
    }
  }
}
/*.body-section {
  position: relative;
  overflow: hidden;
  .cover-img {
    .img {
      position: relative;
     // display: flex;
    //  flex-direction: column;
    }
    .img1 {
      background-image: linear-gradient(to top, rgb(255 255 255), #ffffffb0), linear-gradient(to bottom, rgb(255 255 255), #ffffff42), url('@/assets/images/bottom-bg.png');
      background-repeat: no-repeat;
      background-size: cover;
      width: 100%;
      height: 750px;
    }
    .img2 {
      background-image: linear-gradient(to top, rgb(255 255 255), rgb(255 255 255 / 40%)), linear-gradient(to bottom, rgb(255 255 255 / 72%), rgba(255, 255, 255, 0.2588235294)), url('@/assets/images/interior-large-logistics-warehouse-ai-generative.png');
      background-repeat: no-repeat;
      background-size: cover;
    //  position: relative;
      width: 100%;
      height: 960px;
    }
    .img3 {
      background-image: linear-gradient(to top, rgba(88, 135, 198, 0.74), #5887C6BD), linear-gradient(to bottom, rgb(88, 135, 198, 0.74), #5887C6BD), url('@/assets/images/bottom-bg.png');
      background-repeat: no-repeat;
      background-size: cover;
     // position: relative;
      width: 100%;
      height: 1100px;
    }

    img {
      position: relative;
      top: 5px;
    }
    .content {
      display: flex;
      flex-direction: column;
      align-items: center;

      position: absolute;
      top: 0;
      left: 0;
     // transform: translate(-50%, -50%);
    }
  }
}*/
/*.footer {
  position: relative;
  overflow: hidden;
  .cover-img {
    .img {
      position: relative;
      // display: flex;
      //  flex-direction: column;
    }
    .img3 {
      background-image: linear-gradient(to top, rgba(88, 135, 198, 0.74), #5887C6BD), linear-gradient(to bottom, rgb(88, 135, 198, 0.74), #5887C6BD), url('@/assets/images/bottom-bg.png');
      background-repeat: no-repeat;
      background-size: cover;
      // position: relative;
      width: 100%;
      height: 1100px;
    }

    img {
      position: relative;
      top: 5px;
    }
    .content {
      display: flex;
      flex-direction: column;
      align-items: center;

      position: absolute;
      top: 0;
      left: 0;
    }
  }
}*/
@media (max-width: 768px) {
  .background-image {
    min-height: 40vh;
  }
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
 // margin-top: 60px;
}
</style>
