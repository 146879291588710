<template>
  <div class="home-page">
    <div class="header">
      <div class="cover-img">
        <div class="img"></div>
        <div class="content">
          <Header></Header>
          <div class="wrapper">
            <div class="header">
              <div class="header__section pt-lg-5 pt-md-3 pt-sm-2 mt-lg-4">
                <div :class="{ 'show': showContent }" class="header__section-info">
                  <div class="title">{{ $t('header.aviation') }} <br> <span>BOSCHUNG </span></div>
                </div>
                <transition name="fade">
                  <div v-if="showImage" class="header__section-img"></div>
                </transition>
              </div>
            </div>
            <div class="description" ref="description">
              <b>Boschung</b>
              {{ $t('aviation.boschung.description') }}
            </div>
          </div>
        </div>
      </div>

    </div>
    <div class="body-section">
      <div class="cover-img">
        <div class="img">
          <div class="img2"></div>
          <div class="img1"></div>
          <div class="img2"></div>
          <div class="img1"></div>
          <div class="img2"></div>
          <div class="img1"></div>
          <div class="img2"></div>
          <div class="img2"></div>
          <div class="img1"></div>
          <div class="img2"></div>
          <div class="img1"></div>
          <div class="img2"></div>
          <div class="img1"></div>
          <div class="img2"></div>
        </div>

        <div class="content">

          <div class="box box__left" :class="{ 'show': showLeft}">
            <div class="blue-container blue-container__left">
              <div class="container-content">
                <div class="container-content__title">
                  {{ $t('aviation.boschung.urbanSweeper') }}
                </div>
                <div class="container-content__description container-content__description-left">
                  <div class="container-content__description container-content__description-left">
                    {{ $t('aviation.boschung.urbanSw') }}
                    <br>
                    {{ $t('aviation.boschung.urbanSw2') }}
                  </div>
                </div>
                <div class="container-content__pdfs">
                  <a href="https://www.boschung.com/product/urban-sweeper-s2-0/" class="btn">
                     {{ $t('viewDetails') }}
                  </a>
                </div>
              </div>
              <img class="box-img box-img--left box-img-special" src="~@/assets/images/avio/UrbanSweeperS2.0.png"
                   alt="">
            </div>
          </div>

          <div class="box box__right" :class="{ 'show': showRight}">
            <div class="blue-container blue-container__right">
              <div class="container-content">
                <div class="container-content__title align">
                  {{ $t('aviation.boschung.jetbroom') }} 10000
                </div>
                <div class="container-content__description container-content__description-right">
                  {{ $t('aviation.boschung.jetbroom10') }}
                </div>
                <div class="container-content__pdfs container-content__pdfs-right">
                  <a href="https://www.boschung.com/product/jetbroom-10000/" class="btn">
                     {{ $t('viewDetails') }}
                  </a></div>
              </div>
              <img class="box-img box-img&#45;&#45;right" src="~@/assets/images/avio/Jetbroom10000.png" alt="">
            </div>
          </div>

          <div class="box box__left" :class="{ 'show': showLeft2 }">
            <div class="blue-container blue-container__left">
              <div class="container-content">
                <div class="container-content__title">
                  {{ $t('aviation.boschung.jetbroom') }} 4000
                </div>
                <div class="container-content__description container-content__description-left">
                  {{ $t('aviation.boschung.jetbroom40') }}
                </div>
                <div class="container-content__pdfs">
                  <a href="https://www.boschung.com/product/jetbroom-4000/" class="btn">
                     {{ $t('viewDetails') }}
                  </a>
                </div>
              </div>
              <img class="box-img box-img--left box-img-special" src="~@/assets/images/avio/jetbroom4000.png" alt="">
            </div>
          </div>

          <div class="box box__right" :class="{ 'show': showRight2 }">
            <div class="blue-container blue-container__right">
              <div class="container-content">
                <div class="container-content__title">
                  PONY P3.0
                </div>
                <div class="container-content__description container-content__description-right">
                  {{ $t('aviation.boschung.pony') }}
                </div>
                <div class="container-content__pdfs container-content__pdfs-right">
                  <a href="https://www.boschung.com/product/pony-p3-0/" class="btn">  {{ $t('viewDetails') }}</a>
                </div>
              </div>
              <img class="box-img box-img--right box-img-special" src="~@/assets/images/avio/PonyP3.0.png" alt="">
            </div>
          </div>

          <div class="box box__left" :class="{ 'show': showLeft3 }">
            <div class="blue-container blue-container__left">
              <div class="container-content">
                <div class="container-content__title">
                  {{ $t('aviation.boschung.imsSeriesTitle') }}
                </div>
                <div class="container-content__description container-content__description-left">
                  {{ $t('aviation.boschung.imsSeries') }}
                </div>
                <div class="container-content__pdfs">
                  <a href="https://www.boschung.com/product/ims/" class="btn">
                     {{ $t('viewDetails') }}
                  </a>
                </div>
              </div>
              <img class="box-img box-img--left" src="~@/assets/images/avio/BoschungIMSSERIES.png" alt="">
            </div>
          </div>

          <div class="box box__right" :class="{ 'show': showRight3 }">
            <div class="blue-container blue-container__right">
              <div class="container-content">
                <div class="container-content__title">
                  {{ $t('aviation.boschung.fhSeries') }}
                </div>
                <div class="container-content__description container-content__description-right">
                  {{ $t('aviation.boschung.fhSerie') }}
                </div>
                <div class="container-content__pdfs container-content__pdfs-right">
                  <a href="https://www.boschung.com/product/vehicule-hydraulic-system/" class="btn">
                     {{ $t('viewDetails') }}
                  </a>
                </div>
              </div>
              <img class="box-img box-img--right" src="~@/assets/images/avio/FSerieHehicle.png" alt="">
            </div>
          </div>

          <div class="box box__left" :class="{ 'show': showLeft4 }">
            <div class="blue-container blue-container__left">
              <div class="container-content">
                <div class="container-content__title">
                  {{ $t('aviation.boschung.bMoves') }}
                </div>
                <div class="container-content__description container-content__description-left">
                  {{ $t('aviation.boschung.bMov') }}
                </div>
                <div class="container-content__pdfs">
                  <a href="https://www.boschung.com/product/bmoves/" class="btn">
                     {{ $t('viewDetails') }}
                  </a>
                </div>
              </div>
              <img class="box-img box-img--left" src="~@/assets/images/avio/bMoves-FieldService.png" alt="">

            </div>
          </div>

          <div class="box box__right" :class="{ 'show': showRight4}">
            <div class="blue-container blue-container__right">
              <div class="container-content">
                <div class="container-content__title">
                  {{ $t('aviation.boschung.urbanSweeper2') }}
                </div>
                <div class="container-content__description container-content__description-right">
                  {{ $t('aviation.boschung.urbanSwep2') }}
                  <br>
                  {{ $t('aviation.boschung.urbanSwep3') }}

                </div>
                <div class="container-content__pdfs container-content__pdfs-right">
                  <a href="https://www.boschung.com/product/urban-sweeper-s2-0-autonomous/" class="btn">
                     {{ $t('viewDetails') }}
                  </a>
                </div>
              </div>
              <img class="box-img box-img--right" src="~@/assets/images/avio/UrbanSweeperautonomus.png" alt="">
            </div>
          </div>

          <div class="box box__left" :class="{ 'show': showLeft5 }">
            <div class="blue-container blue-container__left">
              <div class="container-content">
                <div class="container-content__title">
                  {{ $t('aviation.boschung.bVision') }}
                </div>
                <div class="container-content__description container-content__description-left">
                  {{ $t('aviation.boschung.bVis') }}
                </div>
                <div class="container-content__pdfs">
                  <a href="https://www.boschung.com/product/bvision/" class="btn">
                     {{ $t('viewDetails') }}
                  </a>
                </div>
              </div>
              <img class="box-img box-img--left" src="~@/assets/images/avio/bVision.png" alt="">

            </div>
          </div>

          <div class="box box__right" :class="{ 'show': showRight5}">
            <div class="blue-container blue-container__right">
              <div class="container-content">
                <div class="container-content__title">
                  {{ $t('aviation.boschung.snowbooster') }}
                </div>
                <div class="container-content__description container-content__description-right">
                  {{ $t('aviation.boschung.snowboosterSeries') }}
                </div>
                <div class="container-content__pdfs container-content__pdfs-right">
                  <a href="https://www.boschung.com/product/snowbooster/" class="btn">
                     {{ $t('viewDetails') }}
                  </a>
                </div>
              </div>
              <img class="box-img box-img--right" src="~@/assets/images/avio/SnowboosterSeries.png" alt="">
            </div>
          </div>

          <div class="box box__left" :class="{ 'show': showLeft}">
            <div class="blue-container blue-container__left">
              <div class="container-content">
                <div class="container-content__title">
                  ATLAS
                </div>
                <div class="container-content__description container-content__description-left">
                  <div class="container-content__description container-content__description-left">
                    {{ $t('aviation.boschung.atlasDescription') }}
                    <br>{{ $t('aviation.boschung.p1') }}
                    <br>{{ $t('aviation.boschung.p2') }}
                    <br> {{ $t('aviation.boschung.p3') }}

                  </div>
                </div>
                <div class="container-content__pdfs">
                  <a href="https://www.boschung.com/product/atlas/" class="btn">
                     {{ $t('viewDetails') }}
                  </a>
                </div>
              </div>
              <img class="box-img box-img--left box-img-special" src="~@/assets/images/avio/BoschungATLAS.png" alt="">
            </div>
          </div>

          <div class="box box__right" :class="{ 'show': showRight}">
            <div class="blue-container blue-container__right">
              <div class="container-content">
                <div class="container-content__title align">
                  FlexiWet®
                </div>
                <div class="container-content__description container-content__description-right">
                  {{ $t('aviation.boschung.flexiwetDescription') }}
                </div>
                <div class="container-content__pdfs container-content__pdfs-right">
                  <a href="https://www.boschung.com/product/flexiwet/" class="btn">
                     {{ $t('viewDetails') }}
                  </a>
                </div>
              </div>
              <img class="box-img box-img&#45;&#45;right" src="~@/assets/images/avio/FlexiWet.png" alt="">
            </div>
          </div>

          <div class="box box__left" :class="{ 'show': showLeft2 }">
            <div class="blue-container blue-container__left">
              <div class="container-content">
                <div class="container-content__title">
                  Jetbroom 10000 T
                </div>
                <div class="container-content__description container-content__description-left">
                  {{ $t('aviation.boschung.jetbroom10Descr') }}
                </div>
                <div class="container-content__pdfs">
                  <a href="https://www.boschung.com/product/jetbroom-10000-t/" class="btn">
                     {{ $t('viewDetails') }}
                  </a>
                </div>
              </div>
              <img class="box-img box-img--left box-img-special" src="~@/assets/images/avio/jetbroom1000T.png" alt="">
            </div>
          </div>

          <div class="box box__right" :class="{ 'show': showRight2 }">
            <div class="blue-container blue-container__right">
              <div class="container-content">
                <div class="container-content__title">
                  {{ $t('aviation.boschung.sdaSeries') }}
                </div>
                <div class="container-content__description container-content__description-right">
                  {{ $t('aviation.boschung.sdaSeriesDescr') }}
                </div>
                <div class="container-content__pdfs container-content__pdfs-right">
                  <a href="https://www.boschung.com/product/sda/" class="btn">
                     {{ $t('viewDetails') }}
                  </a>
                </div>
              </div>
              <img class="box-img box-img--right box-img-special" src="~@/assets/images/avio/BoschungSDAseries.png"
                   alt="">
            </div>
          </div>

          <div class="box box__left" :class="{ 'show': showLeft3 }">
            <div class="blue-container blue-container__left">
              <div class="container-content">
                <div class="container-content__title">
                  {{ $t('aviation.boschung.fast') }}
                </div>
                <div class="container-content__description container-content__description-left">
                  {{ $t('aviation.boschung.fastDesc') }}
                </div>
                <div class="container-content__pdfs">
                  <a href="https://www.boschung.com/product/fast/" class="btn">
                     {{ $t('viewDetails') }}
                  </a>
                </div>
              </div>
              <img class="box-img box-img--left" src="~@/assets/images/avio/FastSystem.png" alt="">
            </div>
          </div>

          <div class="box box__right" :class="{ 'show': showRight3 }">
            <div class="blue-container blue-container__right">
              <div class="container-content">
                <div class="container-content__title">
                  CombiWet®
                </div>
                <div class="container-content__description container-content__description-right">
                  {{ $t('aviation.boschung.combwetDesc') }}
                </div>
                <div class="container-content__pdfs container-content__pdfs-right">
                  <a href="https://www.boschung.com/product/combiwet/" class="btn">
                     {{ $t('viewDetails') }}
                  </a>
                </div>
              </div>
              <img class="box-img box-img--right" src="~@/assets/images/avio/CombiWet.png" alt="">
            </div>
          </div>

          <div class="box box__left" :class="{ 'show': showLeft4 }">
            <div class="blue-container blue-container__left">
              <div class="container-content">
                <div class="container-content__title">
                  {{ $t('aviation.boschung.iceEarly') }}
                </div>
                <div class="container-content__description container-content__description-left">
                  {{ $t('aviation.boschung.iceEarlyDesc') }}
                </div>
                <div class="container-content__pdfs">
                  <a href="https://www.boschung.com/product/ice-early-warning-system-airport/" class="btn">
                     {{ $t('viewDetails') }}
                  </a>
                </div>
              </div>
              <img class="box-img box-img--left" src="~@/assets/images/avio/iceEarly.png" alt="">

            </div>
          </div>

          <div class="box box__right" :class="{ 'show': showRight4}">
            <div class="blue-container blue-container__right">
              <div class="container-content">
                <div class="container-content__title">
                  FullWet®
                </div>
                <div class="container-content__description container-content__description-right">
                  {{ $t('aviation.boschung.fullwetDesc') }}
                </div>
                <div class="container-content__pdfs container-content__pdfs-right">
                  <a href="https://www.boschung.com/product/fullwet/" class="btn">
                     {{ $t('viewDetails') }}
                  </a>
                </div>
              </div>
              <img class="box-img box-img--right" src="~@/assets/images/avio/CombiWet.png" alt="">
            </div>
          </div>

          <div class="box box__left" :class="{ 'show': showLeft5 }">
            <div class="blue-container blue-container__left">
              <div class="container-content">
                <div class="container-content__title">
                  {{ $t('aviation.boschung.multiSegment') }}
                </div>
                <div class="container-content__description container-content__description-left">
                  {{ $t('aviation.boschung.multiSegmentDesc') }}

                </div>
                <div class="container-content__pdfs">
                  <a href="https://www.boschung.com/product/mf-snowplow/" class="btn">
                     {{ $t('viewDetails') }}
                  </a>
                </div>
              </div>
              <img class="box-img box-img--left" src="~@/assets/images/avio/Snowplows.png" alt="">

            </div>
          </div>

          <div class="box box__right" :class="{ 'show': showRight5}">
            <div class="blue-container blue-container__right">
              <div class="container-content">
                <div class="container-content__title">
                  {{ $t('aviation.boschung.rCondition') }}
                </div>
                <div class="container-content__description container-content__description-right">
                  {{ $t('aviation.boschung.rConditionDesc') }}
                </div>
                <div class="container-content__pdfs container-content__pdfs-right">
                  <a href="https://www.boschung.com/product/r-condition/" class="btn">
                     {{ $t('viewDetails') }}
                  </a>
                </div>
              </div>
              <img class="box-img box-img--right" src="~@/assets/images/avio/r-condition.png" alt="">
            </div>
          </div>

          <div class="box box__left" :class="{ 'show': showLeft3 }">
            <div class="blue-container blue-container__left">
              <div class="container-content">
                <div class="container-content__title">
                  {{ $t('aviation.boschung.rWeather') }}
                </div>
                <div class="container-content__description container-content__description-left">
                  {{ $t('aviation.boschung.rWeatherDesc') }}

                </div>
                <div class="container-content__pdfs">
                  <a href="https://www.boschung.com/product/r-weather/" class="btn">
                     {{ $t('viewDetails') }}
                  </a>
                </div>
              </div>
              <img class="box-img box-img--left" src="~@/assets/images/avio/r-weather.png" alt="">
            </div>
          </div>

          <div class="box box__right" :class="{ 'show': showRight3 }">
            <div class="blue-container blue-container__right">
              <div class="container-content">
                <div class="container-content__title">
                  {{ $t('aviation.boschung.itArc') }}
                </div>
                <div class="container-content__description container-content__description-right">
                  {{ $t('aviation.boschung.itArcDesc') }}

                </div>
                <div class="container-content__pdfs container-content__pdfs-right">
                  <a href="https://www.boschung.com/product/it-arctis/" class="btn">
                     {{ $t('viewDetails') }}
                  </a>
                </div>
              </div>
              <img class="box-img box-img--right" src="~@/assets/images/avio/it.png" alt="">
            </div>
          </div>

          <div class="box box__left" :class="{ 'show': showLeft4 }">
            <div class="blue-container blue-container__left">
              <div class="container-content">
                <div class="container-content__title">
                  {{ $t('aviation.boschung.vpad') }}
                </div>
                <div class="container-content__description container-content__description-left">
                  {{ $t('aviation.boschung.vpadDesc') }}

                </div>
                <div class="container-content__pdfs">
                  <a href="https://www.boschung.com/product/vpad/" class="btn">
                     {{ $t('viewDetails') }}
                  </a>
                </div>
              </div>
              <img class="box-img box-img--left" src="~@/assets/images/avio/Vpad.png" alt="">

            </div>
          </div>

          <div class="box box__right" :class="{ 'show': showRight4}">
            <div class="blue-container blue-container__right">
              <div class="container-content">
                <div class="container-content__title">
                  FH/PL
                </div>
                <div class="container-content__description container-content__description-right">
                  {{ $t('aviation.boschung.fhplDesc') }}
                </div>
                <div class="container-content__pdfs container-content__pdfs-right">
                  <a href="https://www.boschung.com/product/fh-pl-vehicule-hydraulic-system/" class="btn">
                     {{ $t('viewDetails') }}
                  </a>
                </div>
              </div>
              <img class="box-img box-img--right" src="~@/assets/images/avio/FH-PL.png" alt="">
            </div>
          </div>
          <div class="box box__left" :class="{ 'show': showLeft5 }">
            <div class="blue-container blue-container__left">
              <div class="container-content">
                <div class="container-content__title">
                  FH/BL
                </div>
                <div class="container-content__description container-content__description-left">
                  {{ $t('aviation.boschung.fhblDesc') }}
                </div>
                <div class="container-content__pdfs">
                  <a href="https://www.boschung.com/product/fh-bl-vehicule-hydraulic-system/" class="btn">
                     {{ $t('viewDetails') }}
                  </a>
                </div>
              </div>
              <img class="box-img box-img--left" src="~@/assets/images/avio/FH-BL.png" alt="">

            </div>
          </div>

          <div class="marquee">
            <div class="marquee-content">
              <img v-for="logo in partners" :src="logo.src" :alt="logo.alt" :key="logo.id" class="logo" :style="logo.id === 6 ? { padding: '30px 0', maxWidth: '250px' } : {}">
              <img v-for="logo in partners" :src="logo.src" :alt="logo.alt" :key="logo.id" class="logo" :style="logo.id === 6 ? { padding: '30px 0', maxWidth: '250px' } : {}">
              <img v-for="logo in partners" :src="logo.src" :alt="logo.alt" :key="logo.id" class="logo" :style="logo.id === 6 ? { padding: '30px 0', maxWidth: '250px' } : {}">
            </div>
          </div>
        </div>
      </div>

    </div>
    <div class="footer">
      <div class="cover-img">
        <div class="img">
          <div class="img3"></div>
        </div>
        <div class="content">
          <FooterSection></FooterSection>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import FooterSection from "@/components/Footer.vue";
import Header from "@/components/Header.vue";

export default {
  name: 'AvioBoschung',
  components: {
    FooterSection,
    Header
  },
  data() {
    return {
      showImage: false,
      showContent: false,
      showLeft: false,
      showRight: false,
      showLeft2: false,
      showRight2: false,
      showLeft3: false,
      showRight3: false,
      showLeft4: false,
      showRight4: false,
      showLeft5: false,
      showRight5: false,

      partners: [
        {id: 1, src: './partners/aksa.png', alt: 'Logo 1'},
        {id: 2, src: './partners/mulag-logo.png', alt: 'Logo 3'},
        {id: 3, src: './partners/Aviogei.png', alt: 'Logo 3'},
        {id: 4, src: './partners/prekings.png', alt: 'Logo 12'},
        {id: 5, src: './partners/cummins.png', alt: 'Logo 5'},
        {id: 6, src: './partners/bertoli.png', alt: 'Logo 14'},
        {id: 7, src: './partners/dinobus.svg', alt: 'Logo 3'},
        {id: 8, src: './partners/fg-wilson.png', alt: 'Logo 8'},
        {id: 9, src: './partners/green-power.png', alt: 'Logo 9'},
        {id: 10, src: './partners/kohler-sdmo.png', alt: 'Logo 10'},
        {id: 11, src: './partners/mtu.png', alt: 'Logo 11'},
        {id: 12, src: './partners/prekings.png', alt: 'Logo 12'},
        {id: 13, src: './partners/teksan.png', alt: 'Logo 13'},
        {id: 14, src: './partners/boschung-logo.png', alt: 'Logo 3'},
        {id: 15, src: './partners/visa.png', alt: 'Logo 14'},
        {id: 16, src: './partners/mulag-logo.png', alt: 'Logo 3'},
        {id: 17, src: './partners/bertoli.png', alt: 'Logo 14'},
        {id: 18, src: './partners/emsa.png', alt: 'Logo 7'},
        {id: 19, src: './partners/boschung-logo.png', alt: 'Logo 3'},
      ]
    };
  },
  mounted() {
    this.showImage = true;
    setTimeout(() => {
      this.showContent = true;
    }, 500);
    setTimeout(() => {
      this.showLeft = true;
    }, 1000);
    setTimeout(() => {
      this.showRight = true;
    }, 1600);
    setTimeout(() => {
      this.showLeft2 = true;
    }, 2500);
    setTimeout(() => {
      this.showRight2 = true;
    }, 2800);
    setTimeout(() => {
      this.showLeft3 = true;
    }, 3200);
    setTimeout(() => {
      this.showRight3 = true;
    }, 3300);
    setTimeout(() => {
      this.showLeft4 = true;
    }, 3400);
    setTimeout(() => {
      this.showRight4 = true;
    }, 3400);
    setTimeout(() => {
      this.showLeft5 = true;
    }, 3500);
    setTimeout(() => {
      this.showRight5 = true;
    }, 3500);
    setTimeout(() => {
      this.$nextTick(() => {
        this.$refs.description.classList.add('fade-in');
      });
    }, 500);
  },
  methods: {}

}
</script>

<style lang="scss" scoped>
@import "~@/assets/main.scss";

.home-page {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin: 0;
  padding: 0;
  overflow-y: auto;
}

.header {
  position: relative;
  overflow: hidden;

  .cover-img {
    .img {
      background-image: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0.85) 85%), url('@/assets/images/top-bg.png');
      background-repeat: no-repeat;
      background-size: cover;
      position: relative;
      width: 100%;
      height: 1000px;
      @include respond(phone) {
        height: 900px;
      }
      @include respond(phone) {
        height: 1000px;
      }
      @include respond(from-phone-to-tab) {
        height: 1050px;
      }
    }

    img {
      position: relative;
      top: 5px;
    }

    .content {
      position: absolute;
      top: 0;
      width: 100%;
    }
  }
}

.body-section {
  position: relative;
  overflow: hidden;
  .cover-img {
    .img {
      position: relative;
      // display: flex;
      //  flex-direction: column;
    }

    .img1 {
      background-image: linear-gradient(to top, rgb(255 255 255), #ffffffb0), linear-gradient(to bottom, rgb(255 255 255), #ffffff42), url('@/assets/images/bottom-bg.png');
      background-repeat: no-repeat;
      background-size: cover;
      width: 100%;
      height: 640px;
      @include respond(phone) {
        height: 1000px;
      }
      @include respond(lil-phone) {
        height: 1940px;
      }
      @include respond(from-phone-to-tab) {
        height: 870px;
      }
      @include respond(desktop) {
        height: 700px;
      }
    }

    .img2 {
      background-image: linear-gradient(to top, rgb(255 255 255), rgb(255 255 255 / 40%)), linear-gradient(to bottom, rgb(255 255 255 / 72%), rgba(255, 255, 255, 0.2588235294)), url('@/assets/images/interior-large-logistics-warehouse-ai-generative.png');
      background-repeat: no-repeat;
      background-size: cover;
      //  position: relative;
      width: 100%;
      height: 1500px;
      @include respond(phone) {
        height: 1325px;
      }
      @include respond(lil-phone) {
        height: 850px;
      }
      @include respond(from-phone-to-tab) {
        height: 1750px;
      }
      @include respond(desktop) {
        height: 1600px;
      }
    }

    .img3 {
      background-image: linear-gradient(to top, rgba(88, 135, 198, 0.74), #5887C6BD), linear-gradient(to bottom, rgb(88, 135, 198, 0.74), #5887C6BD), url('@/assets/images/bottom-bg.png');
      background-repeat: no-repeat;
      background-size: cover;
      // position: relative;
      width: 100%;
      height: 830px;
      @include respond(from-phone-to-tab) {
        height: 620px;
      }
    }

    img {
      position: relative;
      top: 5px;
    }

    .content {
      position: absolute;
      top: 0;
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 20px;
     // overflow-x: auto;
    //  height: 4200px;
    }
  }

  .height-400 {
    height: 400px !important;
    @include respond(phone) {
      height: 250px !important;
    }
  }

  .marquee {
    width: 100%;
    overflow: hidden;
    position: relative;
    margin-top: 50px;
    @include respond(phone) {
      margin-top: 0;
    }
  }

  .marquee-content {
    display: flex;
    width: calc(100% * 2); /* Double the container size for duplication */
    animation: marquee 20s linear infinite;
  }

  .logo {
    max-width: 150px;
    height: 125px; /* Set a fixed height */
    flex-shrink: 0;
    margin-right: 50px; /* Space between logos */
  }
}

.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .header {
    width: 70vw;
    @include respond(tab-land) {
      width: 95vw;
    }
    @media only screen and (min-width: 1201px) and (max-width: 1499px) {
      width: 95vw;
    }

    &__section {
      display: flex;
      width: 100%;
      justify-content: space-between;
      //   align-items: flex-start;
      align-items: center;
      margin-top: 40px;
      @include respond(phone) {
        flex-direction: column;
        margin-top: 20px;
      }

      &-info {
        display: flex;
        flex-direction: column;
        gap: 10px;
        max-width: 530px;
        width: 100%;
        text-align: left;

        transform: translateY(-100%);
        opacity: 0;
        transition: transform 1.2s ease-out, opacity 2s ease-out;
        @include respond(phone) {
          text-align: center;
        }

        &.show {
          transform: translateX(0);
          opacity: 1;
        }

        .title {
          font-size: 100px;
          font-family: "Montserrat";
          color: $primary-blue;
          font-weight: bold;
          text-transform: uppercase;
          line-height: 0.938;
          text-align: center;
          @include respond(phone) {
            font-size: 30px !important;
            width: 100%;
          }

          span {
            color: $light-blue;
          }
        }

      }

      .fade-enter-active, .fade-leave-active {
        transition: opacity 1s ease-in;
      }

      .fade-enter-from, .fade-leave-to {
        opacity: 0;
      }

      .fade-enter-to, .fade-leave-from {
        opacity: 1;
      }

      &-img {
        background-image: url('~@/assets/images/boschuling-naslovna.png');
        border-radius: 10px;
        width: 500px;
        height: 400px;
        background-size: cover;
        @include respond(phone) {
          display: block !important;
          width: 90vw;
          height: 300px;
          border-radius: 8px;
        }
      }
    }

    .title {
      font-size: 50px;
      font-family: "Montserrat";
      color: $primary-blue;
      font-weight: bold;
      text-transform: uppercase;
      line-height: 1.3;
      @include respond(from-phone-to-tab) {
        font-size: 40px;
      }
    }
  }

  .description {
    max-width: 70vw;
    text-align: center;
    color: $primary-blue;
    font-size: 22px;
    margin-top: 80px;
    font-family: "Karla";
    opacity: 0;
    transition: opacity 3s ease-out;
    @include respond(phone) {
      text-align: justify;
      margin-top: 30px;
      font-size: 20px;
      max-width: 95vw;
    }
    @include respond(lil-phone) {
      margin-top: 20px;
    }
  }

  .pdfs {
    max-width: 70vw;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin-top: 20px;

    .pdf {
      .btn {
        background: linear-gradient(180deg, rgba(93, 132, 183, 0.85) 0%, rgba(36, 85, 151, 0.97) 85%);
        border: none;
        color: white;
        padding: 10px 30px;
        text-transform: uppercase;
        text-decoration: none;
        font-weight: bold;
        transition: transform 0.7s ease-out, color 1s ease;

        &:hover {
          transform: scale(1.1);
        }
      }
    }
  }

  .description.fade-in {
    opacity: 1;
  }
}

.footer {
  position: relative;
  overflow: hidden;

  .cover-img {
    .img {
      position: relative;
      // display: flex;
      //  flex-direction: column;
    }

    .img3 {
      background-image: linear-gradient(to top, rgba(88, 135, 198, 0.74), #5887C6BD), linear-gradient(to bottom, rgb(88, 135, 198, 0.74), #5887C6BD), url('@/assets/images/bottom-bg.png');
      background-repeat: no-repeat;
      background-size: cover;
      // position: relative;
      width: 100%;
      height: 950px;
      @include respond(from-phone-to-tab) {
        height: 620px;
      }
      @include respond(phone) {
        height: 650px;
      }
    }

    img {
      position: relative;
      top: 5px;
    }

    .content {
      position: absolute;
      top: 0;
      width: 100%;
    }
  }
}

</style>
